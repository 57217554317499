const Login = () => import (/* webpackChunkName: "common-group" */ './views/account/Login.vue');
const Dashboard = () => import (/* webpackChunkName: "common-group" */'./views/Dashboard/Dashboard.vue');
const Profile = () => import (/* webpackChunkName: "common-group" */'./views/Profile/Profile.vue');
const Security = () => import (/* webpackChunkName: "common-group" */'./views/Profile/Security.vue');

const UserIndex = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserIndex.vue');
const UserForm = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserForm.vue');
const RoleIndex = () => import (/* webpackChunkName: "user-group" */'./views/Role/RoleIndex.vue');
const RoleForm = () => import (/* webpackChunkName: "user-group" */'./views/Role/RoleForm.vue');
// const UserView = () => import (/* webpackChunkName: "program-group" */'./views/Program/ProgramView.vue');

const LandWaterIndex = () => import (/* webpackChunkName: "landwater-group" */ './views/LandWater/LandWaterIndex');
const LandWaterForm = () => import (/* webpackChunkName: "landwater-group" */ './views/LandWater/LandWaterForm.vue');
const LandWaterView = () => import (/* webpackChunkName: "landwater-group" */ './views/LandWater/LandWaterView.vue');
const MunicipalityReportIndex = () => import (/* webpackChunkName: "landwater-group" */ './views/MunicipalityReport/MunicipalityReportIndex');
const MunicipalityReportForm = () => import (/* webpackChunkName: "landwater-group" */ './views/MunicipalityReport/MunicipalityReportForm.vue');
const MunicipalityReportView = () => import (/* webpackChunkName: "landwater-group" */ './views/MunicipalityReport/MunicipalityReportView.vue');

const ProgramProgressIndex = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressIndex');
const ProgramProgressForm = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressForm.vue');
const ProgramProgressView = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressView.vue');
const ProgramProgressArrange = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressArrange.vue');
const MonthlyProgressIndex = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressIndex');
const MonthlyProgressForm = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressForm.vue');
const MonthlyProgressView = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressView.vue');
const MonthlyProgressReport = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressReport.vue');

const NoticeIndex = () => import (/* webpackChunkName: "notice-group" */ './views/Notice/NoticeIndex.vue');
const NoticeForm = () => import (/* webpackChunkName: "notice-group" */ './views/Notice/NoticeForm.vue');
const NoticeView = () => import (/* webpackChunkName: "notice-group" */ './views/Notice/NoticeView.vue');

const LakePondIndex = () => import (/* webpackChunkName: "river-group" */'./views/LakePond/LakePondIndex.vue');
const LakePondForm = () => import (/* webpackChunkName: "river-group" */'./views/LakePond/LakePondForm.vue');
const LakePondReport = () => import (/* webpackChunkName: "river-group" */'./views/LakePond/LakePondReport.vue');
const RiverSystemIndex = () => import (/* webpackChunkName: "river-group" */'./views/RiverSystem/RiverSystemIndex.vue');
const RiverSystemForm = () => import (/* webpackChunkName: "river-group" */'./views/RiverSystem/RiverSystemForm.vue');
const RiverSystemReport = () => import (/* webpackChunkName: "river-group" */'./views/RiverSystem/RiverSystemReport.vue');

const FeedbackIndex = () => import (/* webpackChunkName: "notice-group" */'./views/Feedback/FeedbackIndex.vue');
const FeedbackForm = () => import (/* webpackChunkName: "notice-group" */'./views/Feedback/FeedbackForm.vue');
const FeedbackView = () => import (/* webpackChunkName: "notice-group" */'./views/Feedback/FeedbackView.vue');

const AttachmentIndex = () => import (/* webpackChunkName: "notice-group" */'./views/Attachment/AttachmentIndex.vue');
const AttachmentForm = () => import (/* webpackChunkName: "notice-group" */'./views/Attachment/AttachmentForm.vue');
const AttachmentView = () => import (/* webpackChunkName: "notice-group" */'./views/Attachment/AttachmentView.vue');


const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            title: 'Login', rule: true,
            requiresVisitor: true,
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'Profile', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/security',
        name: 'security',
        component: Security,
        meta: {
            title: 'Security', rule: true,
            requiresAuth: true,
        }
    },

    //Users Management
    {
        path: '/users',
        name: 'user-index',
        params: true,
        component: UserIndex,
        meta: {
            title: 'Users', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/users/create',
        name: 'user-create',
        params: true,
        component: UserForm,
        meta: {
            title: 'Create User', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/users/:username/edit',
        name: 'user-edit',
        params: true,
        component: UserForm,
        meta: {
            title: 'Edit User', rule: true,
            requiresAuth: true,
        }
    },

    //Role Management
    {
        path: '/roles',
        name: 'role-index',
        params: true,
        component: RoleIndex,
        meta: {
            title: 'Roles', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/roles/create',
        name: 'role-create',
        params: true,
        component: RoleForm,
        meta: {
            title: 'Create Role', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/roles/:id/edit',
        name: 'role-edit',
        params: true,
        component: RoleForm,
        meta: {
            title: 'Edit Role', rule: true,
            requiresAuth: true,
        }
    },

    //Landwater Management
    {
        path: '/landwaters',
        name: 'landwater-index',
        component: LandWaterIndex,
        meta: {
            title: 'Land Water', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/landwaters/create',
        name: 'landwater-create',
        component: LandWaterForm,
        meta: {
            title: 'Create land water', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/landwaters/:uid/edit',
        name: 'landwater-edit',
        params: true,
        component: LandWaterForm,
        meta: {
            title: 'Edit land water', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/landwaters/:uid/view',
        name: 'landwater-view',
        component: LandWaterView,
        meta: {
            title: 'View land water', rule: true,
            requiresAuth: true,
        }
    },

    //Municipality Report
    {
        path: '/municipality-reports',
        name: 'municipality-report-index',
        component: MunicipalityReportIndex,
        meta: {
            title: 'Municipality Report', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/municipality-reports/create',
        name: 'municipality-report-create',
        component: MunicipalityReportForm,
        meta: {
            title: 'Create Municipality Report', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/municipality-reports/:uid/edit',
        name: 'municipality-report-edit',
        params: true,
        component: MunicipalityReportForm,
        meta: {
            title: 'Edit Municipality Report', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/municipality-reports/:uid/view',
        name: 'municipality-report-view',
        params: true,
        component: MunicipalityReportView,
        meta: {
            title: 'View Municipality Report', rule: true,
            requiresAuth: true,
        }
    },

    //Monthly Program Progress
    {
        path: '/monthly-progress',
        name: 'monthly-progress-index',
        component: MonthlyProgressIndex,
        meta: {
            title: 'Monthly Program Progress', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/create',
        name: 'monthly-progress-create',
        component: MonthlyProgressForm,
        meta: {
            title: 'Create Monthly Program Progress', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/:uid/edit',
        name: 'monthly-progress-edit',
        params: true,
        component: MonthlyProgressForm,
        meta: {
            title: 'Edit Monthly Program Progress', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/:uid/view',
        name: 'monthly-progress-view',
        component: MonthlyProgressView,
        meta: {
            title: 'View Monthly Program Progress', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/report',
        name: 'monthly-progress-report',
        component: MonthlyProgressReport,
        meta: {
            title: 'Monthly Program Progress Report', rule: true,
            requiresAuth: true,
        }
    },
    // Program Progress
    {
        path: '/program-progress',
        name: 'program-progress-index',
        component: ProgramProgressIndex,
        meta: {
            title: 'Programs', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/create',
        name: 'program-progress-create',
        component: ProgramProgressForm,
        meta: {
            title: 'Create Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/:id/edit',
        name: 'program-progress-edit',
        params: true,
        component: ProgramProgressForm,
        meta: {
            title: 'Edit Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/:id/view',
        name: 'program-progress-view',
        component: ProgramProgressForm,
        meta: {
            title: 'View Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/arrange',
        name: 'program-progress-arrange',
        component: ProgramProgressArrange,
        meta: {
            title: 'Arrange Program', rule: true,
            requiresAuth: true,
        }
    },


    // Notice
    {
        path: '/notices',
        name: 'notice-index',
        component: NoticeIndex,
        meta: {
            title: 'Notices', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/notices/create',
        name: 'notice-create',
        component: NoticeForm,
        meta: {
            title: 'Create Notice', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/notices/:uid/edit',
        name: 'notice-edit',
        params: true,
        component: NoticeForm,
        meta: {
            title: 'Edit Notice', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/notices/:uid/view',
        name: 'notice-view',
        component: NoticeView,
        meta: {
            title: 'View Notice', rule: true,
            requiresAuth: true,
        }
    },

    //LakePond
    {
        path: '/lakeponds',
        name: 'lakepond-index',
        component: LakePondIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/lakeponds/create',
        name: 'lakepond-create',
        component: LakePondForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/lakeponds/:id/edit',
        name: 'lakepond-edit',
        component: LakePondForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/lakeponds/report',
        name: 'lakepond-report',
        component: LakePondReport,
        meta: {
            requiresAuth: true,
        }
    },

    //River
    {
        path: '/rivers',
        name: 'river-index',
        component: RiverSystemIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/rivers/create',
        name: 'river-create',
        component: RiverSystemForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/rivers/:id/edit',
        name: 'river-edit',
        component: RiverSystemForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/rivers/report',
        name: 'river-report',
        component: RiverSystemReport,
        meta: {
            requiresAuth: true,
        }
    },

    //Feedback
    {
        path: '/feedbacks',
        name: 'feedback-index',
        component: FeedbackIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/create',
        name: 'feedback-create',
        component: FeedbackForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/:id/edit',
        name: 'feedback-edit',
        component: FeedbackForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/feedbacks/:id/show',
        name: 'feedback-view',
        component: FeedbackView,
        meta: {
            requiresAuth: true,
        }
    },

    //Attachment
    {
        path: '/attachments',
        name: 'attachment-index',
        component: AttachmentIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/attachments/create',
        name: 'attachment-create',
        component: AttachmentForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/attachments/:id/edit',
        name: 'attachment-edit',
        component: AttachmentForm,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/attachments/:id/show',
        name: 'attachment-view',
        component: AttachmentView,
        meta: {
            requiresAuth: true,
        }
    },
];

export default routes
