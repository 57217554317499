import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getLandWaters(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/land-water`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createLandWater(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/land-water/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeLandWater(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/land-water`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showLandWater(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/land-water/${params.uid}`,{params:params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editLandWaterByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/land-water/${params.uid}/edit`,{params:params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateLandWater(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/land-water/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getLandWaterExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/land-water-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getLandWaterOffice(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/land-water-office`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateLandWaterStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/land-water-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewLandWater(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/land-water-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
