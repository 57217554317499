import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getRivers(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/river`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createRiver(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/river/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeRiver(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/river`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editRiver(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/river/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateRiver(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.put(`/river/${data.id}`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteRiver(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/river/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    extractRiver(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/river-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    riverReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/river-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
