import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getLakePonds(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/lakepond`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createLakePond(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/lakepond/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeLakePond(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/lakepond`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editLakePond(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/lakepond/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateLakePond(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.put(`/lakepond/${data.id}`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteLakePond(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/lakepond/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    extractLakePond(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/lakepond-import`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    lakepondReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/lakepond-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
