import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getNotices(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/notice`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createNotice(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/notice/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeNotice(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/notice`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showNotice(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/notice/${params}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editNoticeByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/notice/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateNotice(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/notice/${data.uid}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteNotice(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/notice/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updatePopup(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/notice-popup`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
